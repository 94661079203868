<template>
  <div class="freelancer-info">
    <Header/>
    <div class="layout-main">
      <!-- 中间背景图 -->
      <div class="layout-main-center">
        <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
        >
          <!-- <span>更换背景图</span> -->
        </el-upload>
      </div>
    </div>
    <div class="freelancer-center">
      <div class="freelancer-center-left">
        <div class="freelancer-center-left-person">
          <!-- 个人资料 -->
          <div class="user-info-name">
            <div class="user-info-name-left">
              <div class="user-info-name-photo">
                <img v-if="freelancerData.avatar" v-lazy="freelancerData.avatar"/></div>
              <div class="user-info-name-list">
                <ul>
                  <li v-if="freelancerData.weight>100">
                    <Isvg icon-class="platform-certification" />
                    平台推荐
                  </li>
                  <li>
                    <Isvg icon-class="realname-certification" />
                    实名认证 &nbsp; &nbsp;
                    <span v-if="freelancerData.certifications.real_name == 1">已完成</span>
                    <span v-else>未完成</span>
                  </li>
                  <li>
                    <Isvg icon-class="enterprise-certification" />
                    企业认证 &nbsp; &nbsp;
                    <span v-if="freelancerData.certifications.company == 1">已完成</span>
                    <span v-else>未完成</span>
                  </li>
                  <li>
                    <Isvg icon-class="mobile-certification" />
                    手机认证 &nbsp; &nbsp;
                    <span v-if="freelancerData.certifications.mobile == 1">已完成</span>
                    <span v-else>未完成</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="user-info-name-right">
              <!-- 修改个人资料部分 -->
              <div class="user-info-you">
                <div class="user-info-you-name">
                  <h2>{{ freelancerData.name }}</h2>
                  <!--									<p class="line" v-if="freelancerData.online == 1">-->
                  <!--										<span class="g f-12">在线</span>-->
                  <!--										&lt;!&ndash; <button>现在联系</button> &ndash;&gt;-->
                  <!--									</p>-->
                  <!--									<p class="line" v-if="freelancerData.online == 0">-->
                  <!--										<span class="h-57 f-12">离线</span>-->
                  <!--										&lt;!&ndash; <button>联系</button> &ndash;&gt;-->
                  <!--									</p>-->
                  <p style="line-height: 20px;margin-top: 20px;padding-bottom: 10px">{{ freelancerData.slogan }}</p>
                </div>
                <div class="user-info-update">
                  <i class="iconfont icon-like h-b5 wh-22" @click="toLike(freelancerData)"
                     v-if="freelancerData.is_collect == 0"></i>
                  <i class="iconfont icon-like r wh-22" @click="goLike(freelancerData)"
                     v-if="freelancerData.is_collect == 1"></i>
                </div>
              </div>
              <!-- 技能部分 -->
              <div class="user-info-task">
                <div class="user-info-tasks">
                  <strong>技能：</strong>
                  <ul class="user-info-tasks-right">
                    <li v-for="(skillItem, skillIndex) in freelancerData.skills" :key="skillIndex">
                      {{ skillItem.name }}
                      <span v-if="skillIndex + 1 != freelancerData.skills.length">/</span>
                    </li>
                  </ul>
                </div>
                <!--  -->
                <div class="user-task-center">
                  <div class="user-task-center-left">
                    <ul>
                      <li>
                        <strong>报酬：</strong>
                        <span>¥ {{ freelancerData.hourly_rate }}元/小时</span>
                      </li>
                      <li class="user-task-center-address">
                        <strong>地区：</strong>
                        {{
                          checkedAddressName(freelancerData.province_id, freelancerData.city_id, freelancerData.county_id)
                        }}
                      </li>
                      <li>
                        <strong>评价：</strong>
                        <span>
													<el-rate v-model="freelancerData.review_score" disabled text-color="#ff9900"
                                   score-template="{value}" />
													({{ freelancerData.review_count }}个评论)
												</span>
                      </li>
                    </ul>
                  </div>
                  <div class="user-task-center-right">
                    <ul>
                      <li>
                        <strong>加入平台时间：</strong>
                        <span>{{ diffTime.diffTimeDays( freelancerData.join_date ) }}</span>
                      </li>
                      <li>
                        <strong>已完成的工作：</strong>
                        <span>{{ freelancerData.finish_project_num }}单</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="user-task-font">{{ freelancerData.intro }}</div>
            </div>
          </div>
          <!-- 项目展示 -->
          <div class="freelancer-project-show" v-show="false">
            <div class="mine-service-bottom">
              <div class="mine-service-bottom-header">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane label="所有项目" name="first">
                    <div class="mine-service-bottom-all-program">
                      <div class="mb-20"></div>
                      <div class="mine-service-bottom-all-program-center">
                        <MineService></MineService>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="移动开发" name="second">移动开发</el-tab-pane>
                  <el-tab-pane label="电子商务网站" name="third">电子商务网站</el-tab-pane>
                  <el-tab-pane label="AngularJS" name="fourth">AngularJS</el-tab-pane>
                </el-tabs>
              </div>
              <div class="mine-service-bottom-bottom">
                <div class="mb-20"></div>
                <button>查看更多</button>
                <div class="mb-20"></div>
              </div>
            </div>
          </div>
    
          <!-- 工作经验 -->
          <div class="freelancer-work" v-if="freelancerData.professional_open_level==1">
            <div class="person-header">
              <p><strong>工作经验</strong></p>
            </div>
            <div class="user-info-centers">
              <div class="user-info-center" v-for="(expItem,expIndex) in freelancerData.professionals"
                   :key="expItem.expIndex">
                <div class="user-info-center-header">
                  <div class="user-info-center-heder-left">
                    <strong>{{ expItem.position_name }}</strong>
                  </div>
                </div>
                <div class="user-info-center-container">
                  <strong>{{ expItem.company_name }}</strong>
                  <span>( {{ expItem.start_time }} - {{ expItem.end_time }} ) ( {{ expItem.year_count }} 年)</span>
                </div>
                <div class="user-info-center-bottom">
                  <p>{{ expItem.memo }}</p>
                </div>
              </div>
            </div>
            <el-empty v-if="freelancerData.professionals.length == 0" description="尚未添加"></el-empty>
            <div class="user-info-center-button" v-if="freelancerData.professionals.length > 0">
              <div class="mb-20"></div>
              <!--      隐藏掉          -->
              <!--								<el-button type="text" class="f-18">查看更多</el-button>-->
            </div>
          </div>
          <!-- 教育经历 -->
          <div class="freelancer-education" v-if="freelancerData.education_open_level==1">
            <div class="person-header">
              <p><strong>教育经历</strong></p>
            </div>
            <div class="freelancer-educations">
              <div class="user-info-education-fonts">
                <div class="user-info-education-font" v-for="(eduItem, index) in freelancerData.education" :key="index">
                  <div class="user-info-center-header">
                    <div class="user-info-center-heder-left">
                      <strong>{{ eduItem.major_name }}</strong>
                      <span style="font-size:14px">{{ eduItem.major_level_name }}</span>
                    </div>
                  </div>
                  <div class="user-info-education-bottom">
                    <strong>{{ eduItem.school_name }}</strong>
                    <span>( {{ eduItem.start_time }} - {{ eduItem.end_time }} ) ( {{ eduItem.year_count }} 年)</span>
                  </div>
                </div>
              </div>
            </div>
            <el-empty v-if="freelancerData.education.length == 0" description="尚未添加"></el-empty>
            <div class="user-info-education-font-button" v-if="freelancerData.education.length > 1">
              <div class="mb-20"></div>
              <!--              隐藏掉-->
              <!--							<el-button type="text">查看更多</el-button>-->
            </div>
          </div>
          <!-- 荣誉资格-->
          <div class="freelancer-education" v-if="freelancerData.qualification_open_level==1">
            <div class="person-header">
              <p><strong>荣誉证书</strong></p>
            </div>
            <div class="freelancer-educations">
              <div class="user-info-education-fonts">
                <div class="user-info-education-font" v-for="(honorItem, index) in freelancerData.honors" :key="index">
                  <div class="user-info-center-header">
                    <div class="user-info-center-heder-left">
                      <strong>{{ honorItem.qul_name }}</strong>
                      <span style="font-size: 12px"> ( 颁发时间：{{ honorItem.get_year }} )</span>
                    </div>
                  </div>
                  <div class="user-info-education-bottom">
                    <strong> 颁发机构：{{ honorItem.award_by }}</strong>

                  </div>
                </div>
              </div>
            </div>
            <el-empty v-if="freelancerData.honors.length == 0" description="尚未添加"></el-empty>
            <div class="user-info-education-font-button" v-if="freelancerData.honors.length > 1">
              <div class="mb-20"></div>
              <!--              隐藏掉-->
              <!--							<el-button type="text">查看更多</el-button>-->
            </div>
          </div>
		  
		  <!-- 评论部分 -->
		  <div class="freelancer-comment">
		    <div class="freelancer-comment-header">
		      <div class="freelancer-comment-header-center">
		        <div class="freelancer-comment-header-left">
		          <p>
		            <strong class="f-18">评论</strong>
		            <span class="ml-20 f-12 h-57">显示 1 - 5 条评论，共 0 条评论</span>
		          </p>
		        </div>
		        <div class="freelancer-comment-header-right">
		          <el-select v-model="value" clearable placeholder="查看全部" style="height: 26px;">
		            <el-option v-for="item in options" :key="item.value" :label="item.label"
		                       :value="item.value"></el-option>
		          </el-select>
		        </div>
		      </div>
		    </div>
		    <div class="freelancer-comment-content">
		      <div class="freelancer-comment-content-alls">
		        <div class="freelancer-comment-content-alls-item" v-for="(reviewItem, reviewIndex) in reviewListData"
		             :key="reviewIndex">
		          <div class="freelancer-comment-item-left">
		            <strong>{{ reviewItem.task_name }}</strong>
		            <div class="mb-20"></div>
		            <span class="h-57 f-12">{{ reviewItem.contents }}</span>
		            <div class="mb-20"></div>
		            <ul class="freelancer-comment-more">
		              <li v-for="(skill, skillindex) in reviewItem.task_skills" :key="skillindex">{{ skill.name }}</li>
		              <span class="f-12 h-57 cu" v-if="reviewListData.length>4" > +4更多</span>
		            </ul>
		          </div>
		          <div class="freelancer-comment-item-right">
		            <div class="freelancer-comment-item-right-font">
		              <strong>￥{{ reviewItem.task_price }} 元</strong>
		              <div class="mb-20"></div>
		              <el-rate v-model="reviewItem.score" disabled text-color="#ff9900"
		                       score-template="{value}"/>
		            </div>
		          </div>
		  
		          <div class="freelancer-comment-photo">
		            <div>
		              <img @click="toInfo( reviewItem.user_id )" v-lazy="reviewItem.user_avatar" alt="" />
		              <b @click="toInfo( reviewItem.user_id )" class="elopsis f-16">{{ reviewItem.user_name }}</b>
		            </div>
		            <div class="f-12 h-57">评论与 {{ reviewItem.created_at }}</div>
		          </div>
		        </div>
		  
		        <el-empty v-if="reviewListData.length == 0" description="暂无评论"></el-empty>
		      </div>
		      <!-- 分页器 hide-on-single-page-->
		      <div class="block" v-if="reviewListData.length > 0">
		        <p style="width: 100%; text-align: center;cursor: pointer;height: 25px;margin-bottom: 10px"
		           v-if="reviewListPages.total>5"
		           @click="loadNextPage"
		        >点击查看更多</p>
		        <el-pagination
		            v-show="false"
		            @size-change="handleSizeChange"
		            @current-change="handleCurrentChange"
		            :current-page="reviewListPages.page"
		            :page-sizes="[10, 20, 30]"
		            :page-size="reviewListPages.page_size"
		            layout="prev, pager, next,->,total,jumper"
		            :total="reviewListPages.total"
		            background
		            :hide-on-single-page="true"/>
		      </div>
		    </div>
		  </div>
		  
        </div>
      </div>
      <div class="freelancer-center-right">
        <div class="freelancer-center-right-concat">
          <!-- 未登录状态 -->
          <div class="freelancer-center-right-concat-header"><strong>联系自由职业者</strong></div>
          <div class="freelancer-center-right-concat-button" v-show="false">
            <div class="mb-20"></div>
            <el-button type="primary">聘用设计师</el-button>
            <div class="mb-20"></div>
            <span class="f-12 f">登录以通过聊天讨论任何细节</span>
          </div>
          <!-- 登录之后 -->
          <!-- <div class="freelancer-center-right-concat-login">
            <div class="mb-20"></div>
            <span class="f">项目名称</span>
            <div class="mb-10"></div>
            <el-input v-model="project.projectName" class="input" placeholder="输入需要合作的项目名称" style="width: 245px"></el-input>
            <div class="mb-20"></div>
            <el-input v-model="project.projectMessage" class="height-input" placeholder="打个招呼吧～" style="width: 245px"></el-input>
            <div class="mb-20"></div>
            <el-radio-group v-model="project.radio">
              <el-radio :label="3">按小时收费</el-radio>
              <el-radio :label="6">固定价格</el-radio>
            </el-radio-group>
            <div class="mb-20"></div>
            <p class="f-14">
              <span class="f minHour">报酬</span>
              <el-input placeholder="￥100" class="input" style="width: 120px; height: 24px"></el-input>
              <b>元</b>
            </p>
            <div class="mb-20"></div>
            <div class="mb-10"></div>
            <p class="f-14">
              <span class="f maxHour">每周最多工作</span>
              <el-input placeholder="10" class="input" style="width: 120px; height: 24px"></el-input>
              <b>元</b>
            </p>
            <div class="mb-20"></div>
            <el-dropdown split-button type="primary" @click="handleClick">
              雇佣xxx
              <el-dropdown-menu slot="dropdown"><el-dropdown-item @click.native="inviteOther">邀请到其它项目</el-dropdown-item></el-dropdown-menu>
            </el-dropdown>
          </div> -->
          <div class="freelancer-center-right-concat-login">
            <el-dropdown split-button type="primary" @click="toHire" >
              雇佣{{ freelancerData.name }}
              <el-dropdown-menu slot="dropdown" @click.native="inviteOther">
                <el-dropdown-item command="1">邀请到其它项目</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- 职业技能认证 -->
        <div class="freelancer-center-right-task" v-show="false">
          <div class="freelancer-center-right-task-header"><p>职业技能认证</p></div>
          <div class="freelancer-center-right-task-header-bottom">
            <ul>
              <li>
                <span>平面设计</span>
                <strong>89%</strong>
              </li>
              <li>
                <span>平面设计</span>
                <strong>89%</strong>
              </li>
              <li>
                <span>平面设计</span>
                <strong>89%</strong>
              </li>
              <li>
                <span>平面设计</span>
                <strong>89%</strong>
              </li>
              <li>
                <span>平面设计</span>
                <strong>89%</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <el-dialog title="正在进行的招标的任务" :visible.sync="dialogVisible" width="800px" :before-close="handleClose" center>
        <div class="el-dialog-checked">
          <div class="el-dialog-first" v-for="(taskinfo, taskindex) in userTaskList" :key="taskindex">
            <div class="el-dialog-first-left">
              <el-radio v-model="bidForm.task_id" :label="taskinfo.id">{{taskinfo.subject}}</el-radio>
            </div>
            <div class="el-dialog-first-center">
              <strong>{{ taskinfo.budget_amount }}元</strong>
            </div>
            <div class="el-dialog-first-right"><b> {{taskinfo.real_hire_count}} 人参与</b></div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
					<el-button size="small" type="primary" @click="selectTask" class="primary">确认邀请</el-button>
					<el-button size="small" @click="dialogVisible = false">取消</el-button>
				</span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import MineService from '@/components/MineService';
import {evaluates} from '@/api/common';
import {myBidingTaskList, toInviteBid} from '@/api/task';
import {getInfo, collectFreelancer, unCollectFreelancer} from '@/api/freelancer';
import {getToken} from "@/utils/auth";
import checkedAddressName from "@/utils/checkedAddressName";
import diffTime from "@/utils/diffTime";

export default {
  components: {
    MineService
  },
  data() {
    return {
      diffTime,
      checkedAddressName,
      freelancerData: {
        certifications: {},
        education: [],
        professionals: [],
        honors: []
      },
      reviewListData: [],
      reviewListPages: {
        page: 1,
        page_size: 5,
        total: 0,
        page_num: 0
      },
      userTaskList: [],

      activeName: 'first',
      options: [
        {
          value: '选项1',
          label: '全部'
        }
      ],
      value: '',
      startValue: 5,
      pages: {
        page: 1,
        page_size: 10,
        total: 0
      },
      dialogVisible: false,
      project: {
        projectName: '',
        projectMessage: '',
        hourMon: '',
        fixedHour: '',
        reaido: ''
      },
      checked1: '',
      dataLists: ['网页设计', '平面设计', '标志设计'],

      bidForm: {
        task_id: 0
      }
    };
  },
  computed : {
    getCurrentParame(){
      return this.$route.params.id
    }
  },
  watch : {
    getCurrentParame(newId){
      this.loadFreelancerInfo({id: newId});
    }
  },
  mounted() {
    this.loadFreelancerInfo({id: this.$route.params.id});
  },
  methods: {
    async loadFreelancerInfo(data) {
      const res = await getInfo(data);
      if (res.statusCode == 200) {
        res.data.review_score = Number( res.data.review_score )
        this.freelancerData = res.data;
        console.log(this.freelancerData)
        this.loadEvaluates({type: 2, be_user_id: this.freelancerData.id, page: 1, page_size: 5});
      } else {
        this.$notify({
          type: 'error',
          title: '通知',
          message: res.message
        });
      }
    },
    async collect(data, freelancer) {
      if( !getToken() ){
        this.$store.dispatch( 'useUserStore/needLoginModal', true )
        return;
      }
      const res = await collectFreelancer(data);
      if (res.statusCode == 200) {
        this.$notify({
          type: 'success',
          title: '通知',
          message: '您已收藏 [' + freelancer.name + ']'
        });
        freelancer.is_collect = 1;
      } else if (res.statusCode == 511) {
        this.$notify({
          type: 'warning',
          title: '通知',
          message: '请先登录!'
        });
      } else {
        this.$notify({
          type: 'error',
          title: '通知',
          message: res.message
        });
      }
    },
    async unCollect(data, freelancer) {
      if( !getToken() ){
        this.$store.dispatch( 'useUserStore/needLoginModal', true )
        return;
      }
      const res = await unCollectFreelancer(data);
      if (res.statusCode == 200) {
        this.$notify({
          type: 'success',
          title: '通知',
          message: '已取消收藏 [' + freelancer.name + ']'
        });
        freelancer.is_collect = 0;
      } else if (res.statusCode == 511) {
        this.$notify({
          type: 'warning',
          title: '通知',
          message: '请先登录!'
        });
      } else {
        this.$notify({
          type: 'error',
          title: '通知',
          message: res.message
        });
      }
    },
    async loadEvaluates(data, isAppend=false) {
      const res = await evaluates(data);
      if (res.statusCode == 200) {
        res.data.list.forEach(element => {
          element.score = Number(element.score);
        });
        if( isAppend ){
          let theData = res.data.list
          theData.map( item => {
            this.reviewListData.push( item )
          })
        }else{
          this.reviewListData = res.data.list;
        }

        this.reviewListPages = {
          page: Number(res.data.pages.page),
          page_size: Number(res.data.pages.page_size),
          total: Number(res.data.pages.total),
          page_num: Number(res.data.pages.page_num)
        };
      }
    },
    async loadMyBidingTaskList() {
      const res = await myBidingTaskList({page: 1, page_size: 10});
      if (res.statusCode == 200) {
        this.userTaskList = res.data;

        if (res.data.length == 0) {
          this.$confirm('您还没有项目, 前往创建?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
              .then(() => {
                this.$redirectUrl.jumpTo('/task/add');
              })
              .catch(() => {
              });
        } else {
          this.dialogVisible = true;
        }
      }
    },
    selectTask() {
      this.inviteBid( { task_id: this.bidForm.task_id, freelancer_id: this.freelancerData.id } )
    },
    handleAvatarSuccess(res, file) {
      this.avatar = res.data.avatar;
      this.baseForm.avatar = res.data.version;
    },
    beforeAvatarUpload(file) {
      const isJPGOrGIF = ['image/jpeg', 'image/gif', 'image/png'].indexOf(file.type) >= 0;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPGOrGIF) {
        this.$notify({
          type: 'error',
          title: '通知',
          message: '上传头像图片只能是 JPG 格式 GIF格式!'
        });
      }
      if (!isLt2M) {
        this.$notify({
          type: 'error',
          title: '通知',
          message: '上传头像图片大小不能超过 2MB!'
        });
      }
      return isJPGOrGIF && isLt2M;
    },
    skillCallBack(data) {
      this.baseForm.skill_ids = data.skill_ids;
      this.baseForm.skill_cat_id = data.skill_cat_id;
    },
    toLike(freelancer) {
      this.collect({freelancer_id: freelancer.id}, freelancer);
    },
    goLike(freelancer) {
      this.unCollect({freelancer_id: freelancer.id}, freelancer);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleSizeChange(val) {
      this.reviewListPages.page_size = val;
      this.loadEvaluates(
          {
            type: 2,
            be_user_id: this.freelancerData.id,
            page: this.reviewListPages.page,
            page_size: this.reviewListPages.page_size
          }
      )
    },
    handleCurrentChange(val) {
      this.reviewListPages.page = val;
      this.loadEvaluates(
          {
            type: 2,
            be_user_id: this.freelancerData.id,
            page: this.reviewListPages.page,
            page_size: this.reviewListPages.page_size
          }
      )
    },
    loadNextPage(){
      if( !getToken() ){
        this.$store.dispatch( 'useUserStore/needLoginModal', true )
        return
      }
      let thisPage = this.reviewListPages.page + 1;
      if( thisPage <= this.reviewListPages.page_num ){
        this.loadEvaluates(
            {
              type: 2,
              be_user_id: this.freelancerData.id,
              page: thisPage,
              page_size: this.reviewListPages.page_size
            },
            true
        )
      }

    },
    inviteOther() {
      if (!getToken()) {
        this.$store.dispatch('useUserStore/needLoginModal', true )
        return;
      }
      this.dialogVisible = true;
      this.loadMyBidingTaskList();
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    toHire() {
      if (!getToken()) {
        this.$store.dispatch('useUserStore/needLoginModal', true )
        return;
      }
      this.loadMyBidingTaskList();
    },
    toInfo(uid) {
      this.$router.push({path:'/freelancer/info/' + uid});
      this.loadFreelancerInfo({id: uid})
    },
    async inviteBid(data){
      const res = await toInviteBid(data)
      if(res.statusCode==200){
        this.$message.success('邀请成功')
        this.dialogVisible = false;
      }else{
        this.$message.error(res.message)
      }
    }
  }
};
</script>

<style lang="less" scoped>
.ly-3-subTitle {
  margin-top: 15px;
  color: #00A2E6;

  span {
    margin: 0 12px;
    position: relative;

    &:not(:last-of-type)::after {
      content: '';
      position: absolute;
      width: 2px;
      background-color: #00A2E6;
      right: -12px;
      top: 4px;
      bottom: 2px;
    }
  }
}

.freelancer-info {
  background-color: #efefef;

  .layout-main {
    .layout-main-center {
      width: 100%;
      height: 400px;
      background-image: url(~@/assets/task/pexels-miguel-á-padriñán-255379.jpg);
      background-size: cover;
      background-color: transparent;
      object-fit: cover;
    }

    /deep/ .avatar-uploader {
      float: right;
      margin-top: 40px;
      margin-right: 40px;

      span {
        font-size: 12px;
        color: #fff;
      }
    }
  }

  .freelancer-center {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
    margin-top: -300px;

    .freelancer-center-left {
      width: 895px;

      .freelancer-center-left-person {
        width: 895px;

        .user-info-name {
          width: 895px;
          background: #ffffff;
          display: flex;
          padding-bottom: 20px;

          .user-info-name-left {
            width: 200px;
            margin-left: 40px;
            margin-top: 40px;

            .user-info-name-photo {
              width: 200px;
              height: 200px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .user-info-name-list {
              margin-top: 40px;

              li {
                display: flex;
                align-items: center;
                height: 16px;
                margin-bottom: 20px;
                font-size: 12px;
                color: #575757;

                svg {
                  width: 16px;
                  height: 16px;
                  margin-right: 20px;
                }
              }
            }
          }

          .user-info-name-right {
            width: 575px;
            margin-left: 40px;
            margin-top: 40px;

            // 修改个人资料
            .user-info-you {
              width: 100%;
              border-bottom: 1px solid #b5b5b5;
              display: flex;
              justify-content: space-between;

              .user-info-you-name {
                width: 80%;

                h2 {
                  font-size: 18px;
                  margin-bottom: 12px;
                  display: inline;
                }

                .line {
                  margin-left: 20px;
                  display: inline;
                }

                button {
                  margin-left: 10px;
                  padding: 2px 10px;
                  background-color: #00a2e6;
                  border-radius: 8px;
                  font-size: 12px;
                }

                p {
                  color: #575757;
                  font-size: 12px;
                }
              }

              .user-info-update {
                height: 18px;
                display: flex;
                align-items: center;
                cursor: pointer;

                .user-info-update-img {
                  width: 18px;
                  height: 18px;
                  text-align: center;
                  display: inline-block;
                }

                span {
                  margin-left: 10px;
                  font-size: 14px;
                  color: #00a2e6;
                }
              }
            }

            //技能部分
            .user-info-task {
              width: 100%;
              height: 182px;
              display: flex;
              flex-wrap: wrap;
              border-bottom: 1px solid #b5b5b5;

              .user-info-tasks {
                margin-top: 20px;
                display: flex;
                height: 38px;

                strong {
                  width: 50px;
                  font-size: 14px;
                }

                ul {
                  li {
                    font-size: 12px;
                    float: left;
                    margin-bottom: 10px;

                    span {
                      margin: 0 9.6px;
                    }
                  }
                }
              }

              .user-task-center {
                width: 100%;
                height: 100px;
                display: flex;

                .user-task-center-left,
                .user-task-center-right {
                  width: 295px;
                  display: flex;

                  li {
                    font-size: 14px;
                    margin-bottom: 22px;

                    span {
                      color: #575757;
                    }
                  }

                  .user-task-center-address {
                    display: flex;

                    span {
                      margin-right: 20px;
                    }
                  }
                }
              }
            }

            //自我介绍文字部分
            .user-task-font {
              margin-top: 15px;
              font-size: 12px;
              color: #575757;
              line-height: 24px;
            }
          }
        }

        // 项目展示
        .freelancer-project-show {
          width: 100%;
          background-color: #ffffff;

          .mine-service-bottom {
            width: 860px;
            margin: 0 auto;

            .mine-service-bottom-header {
              padding-top: 30px;
              display: flex;
              justify-content: space-between;

              .mine-service-bottom-all-program {
                width: 960px;

                .mine-service-bottom-all-program-center {
                  width: 990px;
                  display: flex;
                  flex-wrap: wrap;
                }
              }

              .mine-service-bottom-header-right {
                input {
                  width: 200px;
                  height: 32px;
                  border: 1px solid #b5b5b5;
                  text-indent: 10px;

                  input[placeholder] {
                    color: #474747;
                  }
                }

                img {
                  width: 16px;
                  height: 16px;
                  position: relative;
                  right: -190px;
                  top: 3.5px;
                }
              }
            }

            .mine-service-bottom-bottom {
              border-top: 1px solid #b5b5b5;
              text-align: center;

              button {
                background-color: #fff;
                color: #00a2e6;
                font-size: 18px;
              }
            }
          }
        }

        // 评论部分
        .freelancer-comment {
		  margin-top: 20px;
          width: 100%;
          mix-height: 300px;
          background-color: #fff;
          padding-bottom: 10px;
          /* padding-bottom: 40px; */
          //评论头部
          .freelancer-comment-header {
            height: 80px;
            border-bottom: 1px solid #b5b5b5;

            .freelancer-comment-header-center {
              width: 815px;
              display: flex;
              justify-content: space-between;
              margin: 0 auto;

              .freelancer-comment-header-left,
              .freelancer-comment-header-right {
                margin-top: 40px;

                /deep/ .el-input__inner {
                  width: 100px;
                  font-size: 12px;
                  color: #575757;
                  height: 24px;
                  line-height: 24px;
                  /* &:placeholder{
                    font-size: 12px;
                  } */
                }

                /deep/ .el-input__icon {
                  height: 24px;
                  line-height: 24px;
                }
              }
            }
          }

          //评论内容
          .freelancer-comment-content {
            width: 815px;
            margin: 0 auto;

            .freelancer-comment-content-alls {
              .freelancer-comment-content-alls-item {
                margin-top: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid #e4e7ed;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .freelancer-comment-item-left {
                  strong {
                    font-size: 16px;
                  }

                  .freelancer-comment-more {
                    display: flex;

                    li {
                      font-size: 12px;
                      color: #00a2e6;

                      &:not(:last-of-type):after{
                        display: inline-block;
                        content: '|';
                        color: #00a2e6;
                        margin: 0 10px;
                      }
                    }
                  }
                }

                .freelancer-comment-item-right {
                  .freelancer-comment-item-right-font {
                    position: relative;
                    top: 40px;
                  }
                }

                .freelancer-comment-photo {
                  cursor: pointer;
                  width: 100%;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 20px;

                  div:first-of-type {
                    display: flex;
                    align-items: center;
                  }

                  img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                  }

                  b {
                    width: 170px;
                    margin-left: 20px;
                    margin-right: 40px;
                  }

                  span {
                    display: block;
                    width: 140px;
                  }
                }
              }
            }
          }
        }

        //工作经验
        .freelancer-work {
          margin-top: 20px;
          width: 100%;
          background-color: #fff;

          .user-info-center {
            width: 815px;
            margin: 0 auto;
            padding-bottom: 20px;
            border-bottom: 1px solid #b5b5b5;

            span {
              margin-left: 10px;
              color: #575757;
            }

            .user-info-center-header {
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .user-info-center-container {
              margin-top: 20px;

              strong {
                font-size: 14px;
              }

              span {
                font-size: 12px;
              }
            }

            .user-info-center-bottom {
              margin-top: 20px;

              p {
                font-size: 12px;
                line-height: 20px;
              }
            }

            &:last-of-type {
              border-bottom: none;
            }
          }

          .user-info-center-button {
            text-align: center;
          }

        }

        //教育经历
        .freelancer-education {
          background-color: #fff;
          margin-top: 20px;
          .user-info-center-header {
            width: 815px;
            margin: 0 auto;
          }

          .user-info-education-fonts {
            .user-info-education-font {
              padding-bottom: 20px;
              width: 815px;
              margin: 0 auto;
              border-bottom: 1px solid #b5b5b5;
              margin-top: 20px;

              .user-info-education-bottom {
                margin-top: 20px;
                margin-bottom: 20px;

                strong {
                  font-size: 14px;
                }

                span {
                  font-size: 12px;
                }
              }

              &:last-of-type {
                border-bottom: none;
              }
            }
          }

          .user-info-education-font-button {
            text-align: center;

            button {
              padding: 0;
              font-size: 18px;
            }
          }

        }
      }
    }

    .freelancer-center-right {
      width: 285px;

      .freelancer-center-right-concat {
        background-color: #FFF;
        width: 100%;

        .freelancer-center-right-concat-header {
          line-height: 50px;
          color: #000;
          border-bottom: 1px solid #b5b5b5;

          strong {
            margin-left: 20px;
          }
        }

        .freelancer-center-right-concat-button {
          margin-left: 20px;
          padding-bottom: 30px;
        }

        .freelancer-center-right-concat-login {
          margin-left: 20px;
          padding-bottom: 20px;
        }
      }

      .freelancer-center-right-concat-login {
        .f-14 {
          b {
            position: relative;
            left: -25px;
            font-size: 14px;
          }
        }
      }

      .freelancer-center-right-task {
        margin-top: 20px;
        background-color: #fff;

        .freelancer-center-right-task-header {
          p {
            margin-left: 20px;
            padding: 20px 0;
          }

          border-bottom: 1px solid #b5b5b5;
        }

        .freelancer-center-right-task-header-bottom {
          li {
            display: flex;
            justify-content: space-between;
            margin: 0 20px;
            line-height: 44px;
          }

          padding-bottom: 20px;
        }
      }
    }
  }
}

/deep/ .el-tabs__item.is-active {
  color: #e4007f;
}

/deep/ .el-tabs__active-bar {
  background-color: #e4007f;
}

/deep/ .el-tabs__item:hover {
  color: #e4007f;
}

/deep/ .el-tabs__item {
  width: 140px;
  text-align: center;
  padding: 0px 0px;
}

/deep/ .tab-fourth {
  margin-left: 20px;
}

/deep/ .el-tabs {
  width: 100%;
}

/deep/ .el-tabs__content {
  width: 895px;
}

.block {
  width: 430px;
  margin: 0 auto;
  margin-top: 40px;
  padding-bottom: 40px;
}

.person-header {
  height: 80px;
  width: 895px;
}

/deep/ .el-input__inner {
  height: 36px;
}

.minHour,
.maxHour {
  margin-right: 20px;
}

.el-dialog-checked {
  /deep/ .el-dialog-first {
    margin-left: 20px;
    margin-bottom: 20px;
    display: flex;
    height: 20px;
    align-items: center;

    .el-checkbox__inner {
      width: 12px;
      height: 12px;
      position: relative;
      top: -5px;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #00a2e6;
      border-color: #00a2e6;
    }

    .el-checkbox__label {
      width: 347px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #000;
      -o-text-overflow: ellipsis;
    }

    .el-dialog-first-left {
      width: 420px;
      line-height: 20px;
      color: #575757;
      border-right: 1px solid #b5b5b5;
    }

    .el-dialog-first-center {
      width: 149px;
      border-right: 1px solid #b5b5b5;
      text-align: center;

      strong {
        font-size: 14px;
        color: #e4007f;
      }
    }

    .el-dialog-first-right {
      margin-left: 50px;

      b {
        color: #575757;
      }
    }
  }
}

.escButton:hover {
  button {
    color: #fff;
  }
}

/deep/ .el-dropdown {
  margin: 30px;
}

/deep/ .el-button-group .el-button--primary:first-child {
  width: 160px;
  overflow: hidden;
}

.user-task-font {
  word-break: break-all;
}

.borderbottom {
  border-bottom: none;
}

.freelancer-comment-content {
  /deep/ .block {
    padding-bottom: 0px;
  }
}

</style>
