<template>
	<div class="mine-service-border">
		<div class="mine-service-border-top"></div>
		<div class="mine-service-border-center">
			<p>为一本德文书写作翻译并注解</p>
			<div class="mine-service-border-center-font">
				<span>80元/小时</span>
				<el-rate v-model="rateValue" disabled show-score text-color="#ff9900" score-template="{value}"></el-rate>
			</div>
		</div>
		<div class="mine-service-border-bottom">
			<div class="mine-service-border-bottom-left" v-if="isPublic">
				<span><a href="#">删除</a></span>
				<span class="span2"><a href="#">修改</a></span>
			</div>
			<div v-if="isPublic" class="mine-service-border-bottom-right"><button>查看评论</button></div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		isPublic: {
			default: false
		}
	},
	data() {
		return {
			rateValue: 5
		};
	}
};
</script>

<style lang="less" scoped>
.mine-service-border {
	width: 271px;
	height: 278px;
	margin-right: 25px;
	margin-bottom: 40px;
	.mine-service-border-top {
		width: 270px;
		height: 150px;
		background-image: url(~@/assets/task/pexels-thirdman-8485732.jpg);
		background-size: cover;
		background-position-y: 170px;
	}
	.mine-service-border-center {
		height: 87px;
		display: flex;
		flex-wrap: wrap;
		border-bottom: 1px solid #b5b5b5;
		p {
			margin-top: 19px;
			font-size: 16px;
		}
		.mine-service-border-center-font {
			width: 100%;
			margin-top: 19px;
			display: flex;
			justify-content: space-between;
			span {
				font-size: 14px;
				color: #575757;
				margin-left: 5px;
			}
			/deep/.el-rate {
				float: right;
				margin-right: 5px;
			}
		}
	}
	.mine-service-border-bottom {
		height: 40px;
		display: flex;
		justify-content: space-between;
		.mine-service-border-bottom-left,
		.mine-service-border-bottom-right {
			margin-top: 16px;
		}
		.mine-service-border-bottom-left {
			line-height: 24px;
			font-size: 14px;

			span {
				margin-left: 10px;
				a {
					color: #575757;
				}
			}
			.span2 {
				margin-left: 20px;
			}
		}
		.mine-service-border-bottom-right {
			button {
				width: 100px;
				line-height: 24px;
				color: #fff;
				font-size: 12px;
				background-color: #00a2e6;
			}
		}
	}
}
</style>
