import request from './ajax'
import qs from 'qs'

export const getList = (data) => request({
  method: "get",
  url: "/freelancer/list",
  params: data
})

export const getInfo = (data) => request({
  method: "get",
  url: "/freelancer/info",
  params: data
})

export const collectFreelancer = (data) => request({
  method: "post",
  url: "/freelancer/collect",
  data: qs.stringify(data) 
})

export const unCollectFreelancer = (data) => request({
  method: "post",
  url: "/freelancer/collect/cancel",
  data: qs.stringify(data) 
})